@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

* {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-display: swap;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sidenav {
  height: 100%;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.4s ease-in-out;
  padding-top: 60px;
  flex-direction: column;
}

@media (max-width: 768px){
  .sidenav {
    width: 100%;
  }
}

.sidenav-items {
  font-size: 16px;
  color: #303030;
  text-decoration: none;
}

/* side nav items on hover */
.sidenav-items:hover {
  /* grey */
  color: #4a6b0e;
}

.divider {
  height: 1px;
  width: 95%;
  background-color: black;
  margin-left: 0%;
}

.MDBCarouselItem img {
  height: auto;
  width: auto;
}

/* carousel on mobile */
@media (max-width: 768px){
  .MDBCarouselItem img {
    height: auto;
    min-width: auto;
    width: auto;
  }
}

.bg-image-vertical {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: auto 100%;
}
  
@media (min-width: 1025px) {
  .h-custom-2 {
    height: 100%;
  }
}

.hero-banner h1 h2 h3 {
  font-size: 38px;
}
.hero-banner .btn {
  position: relative;
  overflow: hidden;
  z-index: 9;
  color: black;
}
.hero-banner .btn-primary {
  color: #eee;
  background-color: #eee;
  border-color: #4a6b0e;
}
.hero-banner .btn:before {
  content: "";
  background: #4a6b0e;
  color: #4a6b0e;
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -ms-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
}
.hero-banner .btn:hover:before {
  color: #fff;
  width: 100%;
}
.hero-banner .btn:hover {
  color: #fff;
}

.icon-hover:hover {
  border-color: #3b71ca !important;
  background-color: white !important;
  color: #3b71ca !important;
}

.icon-hover:hover i {
  color: #3b71ca !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* Initial background color - fully transparent */
  z-index: 1000; /* Ensure the overlay is above other elements */
  transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in; /* Smooth transition effect for background color and backdrop filter */
  pointer-events: none; /* Allow interactions to pass through the overlay */
  backdrop-filter: blur(0);
}

.overlay-active {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: auto; /* Auto-enable interactions on the overlay */
  backdrop-filter: blur(5px);
}


/* Cart */
@media (min-width: 1025px) {
  .h-custom {
  height: 100vh !important;
  }
  }
  
  .card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: .75em;
  padding-right: .75em;
  }
  
  .card-registration .select-arrow {
  top: 13px;
  }
  
  .bg-grey {
  background-color: #eae8e8;
  }
  
  @media (min-width: 992px) {
  .card-registration-2 .bg-grey {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  }
  }
  
  @media (max-width: 991px) {
  .card-registration-2 .bg-grey {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  }
  }

  .product-name {
    position: absolute;
    top: 10px;
    width: 100%;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: #E6E6EA;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
  
  .product-name.visible {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    color: #F7F7FF;
    font-size: large;
    font-weight: bolder;
    transition: opacity 0.5s ease-in-out, color 0.5s ease-in-out;
  }

.featured-image {
  transition: opacity 10s ease-in-out;
}